import React from 'react';
import { EntityLayout, EntitySwitch, isKind } from '@backstage/plugin-catalog';
import { FeatureFlagged } from '@backstage/core-app-api';
import {
  EntityPage as DefaultEntityPage
} from '@platt-internal/backstage-plugin-bootstrap-react';
import {
  EntityPage as FaqsEntityPage
} from '@platt/backstage-plugin-bootstrap-faqs-react';
import {
  DaprEntityContent,
  isDaprAvailable,
} from '@dapr/backstage-plugin-dapr';

const groupPage = DefaultEntityPage.groupPage({});

const userPage = DefaultEntityPage.userPage({});

// const daprCard = (
//   <FeatureFlagged with="dapr-enabled">
//     <EntitySwitch>
//       <EntitySwitch.Case if={e => Boolean(isDaprAvailable(e))}>
//         <Grid item md={4} xs={12}>
//           <ApplicationSummaryCard />
//         </Grid>
//         <Grid item md={8}>
//           <ApplicationComponentsCard />
//         </Grid>
//         <Grid item md={8}>
//           <ApplicationSubscriptionsCard />
//         </Grid>
//         <Grid item md={4}>
//           <ApplicationActorsCard />
//         </Grid>
//       </EntitySwitch.Case>
//     </EntitySwitch>
//   </FeatureFlagged>
// );

const daprRoute = (
  <FeatureFlagged with="dapr-enabled">
    <EntityLayout.Route path="/dapr" title="Dapr" if={isDaprAvailable}>
      <DaprEntityContent />
    </EntityLayout.Route>
  </FeatureFlagged>
)

// const overviewWithDapr = DefaultEntityPage.overviewContent({
//   additionalCard: daprCard
// });

const componentPage = DefaultEntityPage.componentPage({
  dfaultEntityPage: DefaultEntityPage.defaultEntityPage({
    // overview: overviewWithDapr,
    additionalRoutes: daprRoute,
  }),
  serviceEntityPage: DefaultEntityPage.serviceEntityPage({
    // overview: overviewWithDapr,
    additionalRoutes: daprRoute,
  }),
  websiteEntityPage: DefaultEntityPage.websiteEntityPage({
    // overview: overviewWithDapr,
    additionalRoutes: daprRoute,
  }),
});

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={DefaultEntityPage.apiPage()} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={DefaultEntityPage.systemPage()} />
    <EntitySwitch.Case if={isKind('domain')} children={DefaultEntityPage.domainPage()} />
    <EntitySwitch.Case if={isKind('resource')} children={DefaultEntityPage.resourceEntityPage()} />
    <EntitySwitch.Case if={isKind('meetingRepo')} children={FaqsEntityPage.meetingRepoPage()} />

    <EntitySwitch.Case>{DefaultEntityPage.defaultEntityPage()}</EntitySwitch.Case>
  </EntitySwitch>  
);
