import React, { PropsWithChildren } from 'react';
import { SidebarDivider, SidebarItem } from '@backstage/core-components';
import { Root as DefaultRoot } from '@platt-internal/backstage-plugin-bootstrap-react';
import { FaqsSidebarItems } from '@platt/backstage-plugin-bootstrap-faqs-react';
import MapIcon from '@material-ui/icons/MyLocation';

const additionalMenuItems = (
  <>
    <FaqsSidebarItems />
    <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
    <SidebarDivider />
  </>
);

export const Root = ({ children }: PropsWithChildren<{}>) => {

  return (
    <DefaultRoot additionalMenuItems={additionalMenuItems}>
      {children}
    </DefaultRoot>
  );
};
