import {
  createPlugin,
} from '@backstage/core-plugin-api';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  AppRegistrationPicker,
  AppRegistrationPickerSchema,
  AppRegistrationEntityPicker,
  AppRegistrationEntityPickerSchema,
  SubscriptionEntityPicker,
  SubscriptionEntityPickerSchema,
} from './components/fields';

import { rootRouteRef } from './routes';

export const msgraphExtensionPlugin = createPlugin({
  id: 'scaffolder-msgraph-extension',
  routes: {
    root: rootRouteRef,
  },
});

export const MSGraphAppRegistrationPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'MSGraphAppRegistrationPicker',
    component: AppRegistrationPicker,
    schema: AppRegistrationPickerSchema.schema,
  })
);

export const MSGraphAppRegistrationEntityPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AppRegistrationEntityPicker',
    component: AppRegistrationEntityPicker,
    schema: AppRegistrationEntityPickerSchema.schema,
  })
);

export const AzureSubscriptionEntityPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AzureSubscriptionEntityPicker',
    component: SubscriptionEntityPicker,
    schema: SubscriptionEntityPickerSchema.schema,
  })
);
