import { AppRegistrationInfo } from '../types';

export const parseAppRegInfo = (strInfo: string): AppRegistrationInfo => {
  const info = JSON.parse(strInfo);
  const { entityRef, tenantId, clientId, objectId }= info;
  return {
    entityRef,
    tenantId,
    clientId,
    objectId
  };
};

export const stringifyAppRegInfo = (info: AppRegistrationInfo): string => {
  return JSON.stringify(info);
};
