import React, { useEffect, useState } from 'react';
import useDebounce from 'react-use/esm/useDebounce';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {
  configApiRef,
  microsoftAuthApiRef,
  useApi
} from '@backstage/core-plugin-api';
import { useTemplateSecrets } from '@backstage/plugin-scaffolder-react';
import { getAdditionalScopesForMsGraph } from '../../../utils';
import { AppRegistrationPickerSchema } from './schema';
import { AppRegistrationPickerState } from './types';

export const AppRegistrationPicker = (
  props: typeof AppRegistrationPickerSchema.TProps
) => {
  const { uiSchema, onChange } = props;
  const { secrets, setSecrets } = useTemplateSecrets();
  const [state, setState] = useState<AppRegistrationPickerState>({
    name: ''
  });

  const { name } = state;
  const config = useApi(configApiRef);

  const microsoftAuthApi = useApi(microsoftAuthApiRef);
  const onChangeState = (current: AppRegistrationPickerState) => setState((prev => ({
    ...prev,
    ...current,
  })));

  useEffect(() => {
    onChange(state.name);

  }, [state, onChange]);

  useDebounce(async () => {
    const { requestUserCredentials } = uiSchema?.['ui:options'] ?? {};
    if (!requestUserCredentials) {
      // No need to request user credentials
      return;
    }

    // You already have the secrets
    if (secrets[requestUserCredentials.secretsKey]) return;

    const additionalScopes = getAdditionalScopesForMsGraph(config);
    const token = await microsoftAuthApi.getAccessToken(additionalScopes);
    setSecrets({ [requestUserCredentials.secretsKey]: token });

  }, 1000, [uiSchema]);

  return (
    <>
      <FormControl
        margin="normal"
        required
      >
        <TextField
          id="nameInput"
          label="App Name*"
          onChange={e => onChangeState({ name: e.target.value })}
          helperText="The name of the app"
          value={name}
        />
      </FormControl>
    </>
  );
}
