import { AzureSubscriptionInfo } from '../types';

export const parseAzureSubscriptionInfo = (strInfo: string): AzureSubscriptionInfo => {
  const info = JSON.parse(strInfo);
  const { entityRef, tenantId, subscriptionId }= info;
  return {
    entityRef,
    tenantId,
    subscriptionId
  };
};

export const stringifyAzureSubscriptionInfo = (info: AzureSubscriptionInfo): string => {
  return JSON.stringify(info);
};
