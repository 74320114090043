import React from 'react';
import { Entity } from '@backstage/catalog-model';

export type SubscriptionDisplayNameProps = {
  entityRef?: Entity | string;
};

export const SubscriptionDisplayName = (props: SubscriptionDisplayNameProps) => {
  const { entityRef } = props;

  if (!entityRef) {
    return <></>;
  }
  if (typeof entityRef === 'string') {
    return <>{entityRef}</>;
  }

  const displayName = entityRef.metadata.title ?? entityRef.metadata.name;
  const subscriptionId = entityRef.metadata.annotations?.['azure.com/subscription-id'];
  const content = `${displayName} (${subscriptionId?.substring(0, 8)}...)`;
  return <>{content}</>  
};

