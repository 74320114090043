import React, { useCallback } from 'react';
import useAsync from 'react-use/esm/useAsync';
import useDebounce from 'react-use/esm/useDebounce';
import { FormControl, TextField } from '@material-ui/core';
import Autocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { useTemplateSecrets } from '@backstage/plugin-scaffolder-react';
import { configApiRef, microsoftAuthApiRef, useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  entityPresentationApiRef,
  EntityRefPresentationSnapshot
} from '@backstage/plugin-catalog-react';
import {
  getAzureSubscriptionInfoFromEntity,
  parseAppRegInfo,
  SPEC_TYPE_AZURE_SUBSCRIPTION,
  stringifyAzureSubscriptionInfo
} from'@platt/backstage-plugin-scaffolder-msgraph-common';
import { SubscriptionEntityPickerSchema } from './schema';
import { SubscriptionDisplayName } from './SubscriptionDisplayName';
import { getAdditionalScopesForMsGraph } from '../../../utils';

const stringifyInfo = (entity: Entity) => {

  const appRegInfo = getAzureSubscriptionInfoFromEntity(entity);
  if (!appRegInfo) {
    throw new Error('Invalid app registration entity');
  }
  return stringifyAzureSubscriptionInfo(appRegInfo);
};

const labelDisplayName = (entityRef?: Entity) => {
  if (!entityRef) {
    return '';
  }
  const displayName = entityRef.metadata.title ?? entityRef.metadata.name;
  const subscriptionId = entityRef.metadata.annotations?.['azure.com/subscription-id'];
  return `${displayName} (${subscriptionId?.substring(0, 8)}...)`;
};

export const SubscriptionEntityPicker = (
  props: typeof SubscriptionEntityPickerSchema.TProps
) => {
  const {
    formData,
    idSchema,
    onChange,
    rawErrors,
    required,
    uiSchema,
  } = props;
  const { secrets, setSecrets } = useTemplateSecrets();
  
  const config = useApi(configApiRef);
  const catalogApi = useApi(catalogApiRef);
  const entityPresentationApi = useApi(entityPresentationApiRef);
  const microsoftAuthApi = useApi(microsoftAuthApiRef);

  const onSelect = useCallback(
    (_:any, ref: string | Entity | null, reason: AutocompleteChangeReason) => {
      if (typeof ref !== 'string') {
        onChange(ref ? stringifyInfo(ref) : undefined);
      } else {
        if (reason === 'blur') {
          if (formData !== ref) {
            onChange(undefined);
          }
        }
      }
    },
    [onChange, formData]
  );

  useDebounce(async () => {
    const { requestUserCredentials } = uiSchema?.['ui:options'] ?? {};
    if (!requestUserCredentials) {
      // No need to request user credentials
      return;
    }

    // You already have the secrets
    if (secrets[requestUserCredentials.secretsKey]) return;

    const additionalScopes = getAdditionalScopesForMsGraph(config);
    const token = await microsoftAuthApi.getAccessToken(additionalScopes);
    setSecrets({ [requestUserCredentials.secretsKey]: token });

  }, 1000, [uiSchema]);

  const { value: entities, loading } = useAsync(async () => {
    const fields = [
      'metadata.name',
      'metadata.namespace',
      'metadata.title',
      'metadata.description',
      'metadata.annotations',
      'kind',
    ];
    const { items } = await catalogApi.getEntities(
      { 
        filter: {
          kind: 'Resource',
          'spec.type': SPEC_TYPE_AZURE_SUBSCRIPTION
        },
        fields
      }
    );
    
    const entityRefToPresentation = new Map<
      string,
      EntityRefPresentationSnapshot
    >(
      await Promise.all(
        items.map(async item => {
          const presentation = await entityPresentationApi.forEntity(item)
            .promise;
          return [stringifyEntityRef(item), presentation] as [
            string,
            EntityRefPresentationSnapshot,
          ];
        }),
      ),
    );

    return { catalogEntities: items, entityRefToPresentation };
  });

  const previousSelect = formData ? parseAppRegInfo(formData) : undefined;
  const selectedEntity = entities?.catalogEntities?.find(
      entity => stringifyEntityRef(entity) === previousSelect?.entityRef
  ) ?? null;

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        disabled={
          required &&
          entities?.catalogEntities?.length === 1
        }
        id={idSchema?.$id}
        autoSelect
        loading={loading}
        onChange={onSelect}
        options={entities?.catalogEntities || []}
        getOptionLabel={option =>
          // option can be a string due to freeSolo.
          typeof option === 'string'
            ? option
            : labelDisplayName(option)
        }
        renderInput={params => (
          <TextField
            {...params}
            label="Azure Subscription"
            helperText="Choose Azure subscription resource"
            required={required}
            error={rawErrors?.length > 0 && !formData}
          />
        )}
        renderOption={option => <SubscriptionDisplayName entityRef={option} />}
        value={selectedEntity}
      />
    </FormControl>
  );
};
