import { makeFieldSchema } from '@backstage/plugin-scaffolder-react';

export const SubscriptionEntityPickerSchema = makeFieldSchema({
  output: z => z.string(),
  uiOptions: z =>
    z.object({
      // Unfortunatly requestUserCredentials is defined but is not supported yet.
      requestUserCredentials: z
        .object({
          secretsKey: z
          .string()
          .describe('Key used within the template secrets context to store the credential'),
        })
        .optional()
        .describe('If defined will requst user credential to auth the Azure access'),
      })
    .optional()
});
