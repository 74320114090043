import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { AzureSubscriptionInfo } from '../types';

export const getAzureSubscriptionInfoFromEntity = (entity: Entity): AzureSubscriptionInfo | undefined => {
  const entityRef  = stringifyEntityRef(entity);
  const tenantId = entity.metadata.annotations?.['azure.com/tenant-id'];
  const subscriptionId = entity.metadata.annotations?.['azure.com/subscription-id'];

  if (!tenantId || !subscriptionId) {
    return undefined;
  }

  return {
    entityRef,
    tenantId,
    subscriptionId,
  };

};
