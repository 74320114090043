import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { AppRegistrationInfo } from '../types';

export const getAppRegistrationInfoFromEntity = (entity: Entity): AppRegistrationInfo | undefined => {
  const entityRef  = stringifyEntityRef(entity);
  const tenantId = entity.metadata.annotations?.['azure.com/entraid-app-tenant-id'];
  const clientId = entity.metadata.annotations?.['azure.com/entraid-app-client-id'];
  const objectId = entity.metadata.annotations?.['azure.com/entraid-app-object-id'];

  if (!tenantId || !clientId || !objectId) {
    return undefined;
  }

  return {
    entityRef,
    tenantId,
    clientId,
    objectId
  };

};
