export type AppRegistrationInfo = {
  entityRef: string;
  tenantId: string;
  clientId: string;
  objectId: string;
};
export const SPEC_TYPE_ENTRAID_APPLICATION = 'entraid-application';

export type AzureSubscriptionInfo = {
  entityRef: string;
  tenantId: string;
  subscriptionId: string;
};
export const SPEC_TYPE_AZURE_SUBSCRIPTION = 'azure-subscription';
